export const ENUM_CONFIRM_STATUS = {
  notConfirm: {
    value: 0,
    label: '未确认',
  },
  waitExamine: {
    value: 1,
    label: '已确认待审核',
  },
};

export const ENUM_COMPENSATION_TYPE = {
  cloudWarehouse: {
    value: '0',
    label: '网仓赔付',
  },
  express: {
    value: '1',
    label: '快递赔付',
  },
};
