import { Button, message, Modal, Space } from 'antd';
import type { FilterConfigData } from 'egenie-utils';
import { formatFilterConfigData, MainSubStructureModel, NormalProgramme, request, TimeStampFormatter, Permission, ExportStore, ImgFormatter } from 'egenie-utils';
import React from 'react';
import { formatWarehouseChoice, getOwner, getWarehouse, ImportFile, LogTooltip } from '../../../utils';
import type { MainItem } from './types';
import { ENUM_COMPENSATION_TYPE, ENUM_CONFIRM_STATUS } from './constants';
import type { BaseData, DictData } from 'egenie-common';
import { getStaticResourceUrl, objToDict, destroyAllModal, RenderByCondition, renderModal, toFixed } from 'egenie-common';
import { CreateCompensationModal } from './createCompensationModal';
import { nanoid } from 'nanoid';

export class Store {
  constructor() {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
    this.handleInit();
  }

  private handleInit = () => {
    getOwner()
      .then((data) => this.normalProgramme.filterItems.addDict({ ownerIds: data }));

    request<BaseData<Array<{ courierId: string; courierName: string; }>>>({
      url: '/api/cloud/wms/rest/courier/queryAll',
      method: 'post',
    })
      .then((info) => this.normalProgramme.filterItems.addDict({
        courierIds: (info.data || []).map((item) => ({
          ...item,
          value: item.courierId,
          label: item.courierName,
        })),
      }));

    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        itemList: 'user',
        dictList: 'compensation_reason_type',
        module: '',
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, { compensation_reason_type: 'reasonTypes' });
        this.normalProgramme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });

    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/compensation/enum/status' })
      .then((info) => this.normalProgramme.filterItems.addDict({ confirmType: objToDict(info.data) }));

    getWarehouse()
      .then((info) => {
        this.normalProgramme.filterItems.updateFilterItem([
          {
            field: 'warehouseId',
            data: info,
            value: formatWarehouseChoice(info, 'warehouseId'),
          },
        ]);
      });
  };

  public handleDeleteCompensation = (row: MainItem) => {
    Modal.confirm({
      centered: true,
      content: '确定删除吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/wms/rest/compensation/delete',
          method: 'POST',
          data: { ids: row.id },
        });
        this.mainSubStructureModel.gridModel.clearToOriginal();
        this.mainSubStructureModel.gridModel.onRefresh();
        message.success('删除成功');
      },
    });
  };

  private handleConfirmCompensation = (rows: MainItem[]) => {
    if (rows.length === 0) {
      const error = '请选择数据';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    if (rows.some((item) => item.confirmStatus !== ENUM_CONFIRM_STATUS.notConfirm.value)) {
      const error = `只能勾选${ENUM_CONFIRM_STATUS.notConfirm.label}数据`;
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    Modal.confirm({
      centered: true,
      content: '确定赔付确认吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/wms/rest/compensation/confirm',
          method: 'POST',
          data: {
            ids: rows.map((item) => item.id)
              .join(','),
          },
          headers: { warehouseId: this.normalProgramme.filterItems.params.warehouseId || '' },
        });

        this.mainSubStructureModel.gridModel.clearToOriginal();
        this.mainSubStructureModel.gridModel.onRefresh();
        message.success('操作成功');
      },
    });
  };

  private handleExamineCompensation = (rows: MainItem[]) => {
    if (rows.length === 0) {
      const error = '请选择数据';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    if (rows.some((item) => item.confirmStatus !== ENUM_CONFIRM_STATUS.waitExamine.value)) {
      const error = `只能勾选${ENUM_CONFIRM_STATUS.waitExamine.label}数据`;
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    Modal.confirm({
      centered: true,
      content: '确定赔付审核吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/wms/rest/compensation/approve',
          method: 'POST',
          data: {
            ids: rows.map((item) => item.id)
              .join(','),
          },
          headers: { warehouseId: this.normalProgramme.filterItems.params.warehouseId || '' },
        });

        this.mainSubStructureModel.gridModel.clearToOriginal();
        this.mainSubStructureModel.gridModel.onRefresh();
        message.success('操作成功');
      },
    });
  };

  public handleCreateOrEditCompensation = (mainItem?: MainItem) => {
    renderModal(
      <CreateCompensationModal
        callback={() => {
          destroyAllModal();
          if (mainItem?.id) {
            this.mainSubStructureModel.gridModel.onRefresh();
          } else {
            this.mainSubStructureModel.gridModel.onQuery();
          }
        }}
        courierData={this.normalProgramme.filterItems.dict.courierIds || []}
        mainItem={mainItem}
        onCancel={destroyAllModal}
        ownerData={this.normalProgramme.filterItems.dict.ownerIds || []}
        responsibleUserData={this.normalProgramme.filterItems.dict.user || []}
      />
    );
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '创建赔付',
        permissionId: '5021',
        handleClick: () => this.handleCreateOrEditCompensation(),
      },
      {
        text: '赔付审核',
        permissionId: '6033',
        handleClick: () => this.handleExamineCompensation(this.mainSubStructureModel.gridModel.selectRows),
      },
      {
        text: '赔付确认',
        permissionId: '5022',
        handleClick: () => this.handleConfirmCompensation(this.mainSubStructureModel.gridModel.selectRows),
      },
      {
        text: '导出',
        handleClick: () => {
          const ids = Array.from(this.mainSubStructureModel.gridModel.selectedIds)
            .join(',');
          const fileName = '赔付列表';
          const exportType = 'compensation_order';
          const queryParam = this.normalProgramme.filterItems.params;
          const queryParamShow = this.normalProgramme.filterItems.translateParams.join(' ');

          if (ids) {
            this.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                this.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
      {
        text: '导入',
        permissionId: '5',
        handleClick: () => {
          renderModal(
            <ImportFile
              callback={((params) => {
                const fd = new FormData();
                for (const key in params) {
                  if (Object.prototype.hasOwnProperty.call(params, key)) {
                    fd.append(key, params[key]);
                  }
                }

                return request({
                  url: '/api/cloud/wms/rest/compensation/import/compensation',
                  method: 'post',
                  data: fd,
                })
                  .then(() => {
                    message.success('上传成功');
                    this.mainSubStructureModel.onQuery();
                    destroyAllModal();
                  });
              })}
              onCancel={destroyAllModal}
              templateUrl={getStaticResourceUrl('pc/ts/egenie-cloud-wms/downloadTemplate/赔付导入模板.xlsx')}
            />
          );
        },
      },
    ],
    grid: {
      columns: [
        {
          name: '操作',
          key: 'operate',
          width: 150,
          frozen: true,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <>
                <Permission permissionId="60162_44">
                  <RenderByCondition show={row.confirmStatus === ENUM_CONFIRM_STATUS.notConfirm.value}>
                    <Button
                      onClick={() => this.handleCreateOrEditCompensation(row)}
                      size="small"
                      type="link"
                    >
                      编辑
                    </Button>
                  </RenderByCondition>
                </Permission>
                <Permission permissionId="60162_45">
                  <RenderByCondition show={row.confirmStatus === ENUM_CONFIRM_STATUS.notConfirm.value}>
                    <Button
                      onClick={() => this.handleDeleteCompensation(row)}
                      size="small"
                      type="link"
                    >
                      删除
                    </Button>
                  </RenderByCondition>
                </Permission>
                <LogTooltip
                  children={(
                    <Button
                      size="small"
                      type="link"
                    >
                      日志
                    </Button>
                  )}
                  entityId={row.id}
                  height={230}
                  logBizModuleCode={150000}
                  popoverProps={{
                    title: '日志',
                    trigger: 'click',
                  }}
                  width={800}
                />
              </>
            );
          },
        },
        {
          key: 'confirmStatusName',
          name: '状态',
          width: 120,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 200,
        },
        {
          key: 'platformOrderCode',
          name: '平台单号',
          width: 200,
        },
        {
          key: 'waybillCode',
          name: '快递单号',
          width: 200,
        },
        {
          key: 'compensationTypeName',
          name: '赔付类型',
          width: 80,
        },
        {
          key: 'reasonTypeName',
          name: '问题类型',
          width: 100,
        },
        {
          key: 'reason',
          name: '问题描述',
          width: 100,
        },
        {
          key: 'compensationResult',
          name: '处理结果',
          width: 100,
        },
        {
          key: 'courierPicList',
          name: '快递截图',
          width: 150,
          formatter: ({ row }: { row: MainItem; }) => {
            const courierPicList: string[] = Array.isArray(row.courierPicList) ? row.courierPicList.filter(Boolean) : [];

            return (
              <Space size="small">
                {
                  courierPicList.map((item) => (
                    <ImgFormatter
                      key={nanoid()}
                      previewHeight={500}
                      previewWidth={500}
                      value={item}
                    />
                  ))
                }
              </Space>
            );
          },
        },
        {
          key: 'valuePicList',
          name: '价值截图',
          width: 150,
          formatter: ({ row }: { row: MainItem; }) => {
            const valuePicList: string[] = Array.isArray(row.valuePicList) ? row.valuePicList.filter(Boolean) : [];

            return (
              <Space size="small">
                {
                  valuePicList.map((item) => (
                    <ImgFormatter
                      key={nanoid()}
                      previewHeight={500}
                      previewWidth={500}
                      value={item}
                    />
                  ))
                }
              </Space>
            );
          },
        },
        {
          key: 'compensationPrice',
          name: '理赔金额',
          width: 80,
          formatter: ({ row }: { row: MainItem; }) => (
            <span>
              {toFixed(row.compensationPrice, 2)}
            </span>
          ),
        },
        {
          key: 'approveUserName',
          name: '审核人',
          width: 150,
        },
        {
          key: 'creatorName',
          name: '创建人',
          width: 150,
        },
        {
          key: 'responsibleUserName',
          name: '责任人',
          width: 150,
        },
        {
          key: 'courierName',
          name: '快递公司',
          width: 150,
        },
        {
          key: 'createTime',
          name: '创建时间',
          sortable: true,
          width: 150,
          formatter: ({ row }: { row: MainItem; }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          key: 'approveTime',
          name: '审核时间',
          sortable: true,
          width: 150,
          formatter: ({ row }: { row: MainItem; }) => <TimeStampFormatter value={row.approveTime}/>,
        },
        {
          key: 'wmsPayTransOrderNo',
          name: '支付流水号',
          width: 200,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      sumColumns: ['compensationPrice'],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/afterSales/compensationManage/index',
    },
    pageId: '60162',
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request({
          url: '/api/cloud/wms/rest/compensation/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
          headers: { warehouseId: filterParams?.warehouseId || '' },
        });
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    count: 6,
    filterItems: [
      {
        type: 'select',
        field: 'dateType',
        label: '日期类型',
        value: '1',
        data: [
          {
            value: '1',
            label: '创建时间',
          },
          {
            value: '2',
            label: '审核时间',
          },
        ],
      },
      {
        field: 'dateValue',
        label: '日期',
        type: 'dateRange',
      },
      {
        field: 'ownerIds',
        label: '货主',
        type: 'select',
        mode: 'multiple',
        isParamList: true,
      },
      {
        field: 'confirmType',
        label: '状态',
        type: 'select',
      },
      {
        field: 'platformOrderCode',
        label: '平台单号',
        type: 'input',
      },
      {
        field: 'waybillCode',
        label: '快递单号',
        type: 'input',
      },
      {
        field: 'reasonTypes',
        label: '问题类型',
        type: 'select',
        mode: 'multiple',
        isParamList: true,
      },
      {
        field: 'compensationType',
        label: '理赔类型',
        type: 'select',
        data: Object.values(ENUM_COMPENSATION_TYPE),
      },
      {
        field: 'courierIds',
        label: '快递公司',
        type: 'select',
        mode: 'multiple',
        isParamList: true,
      },
      {
        field: 'warehouseId',
        label: '仓库',
        type: 'select',
      },
      {
        field: 'wmsPayTransOrderNo',
        label: '支付流水号',
        type: 'input',
      },
    ],
    handleSearch: this.mainSubStructureModel.gridModel.onQuery,
  });

  public exportStore: ExportStore = new ExportStore({ parent: null });
}
