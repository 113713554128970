import type { FormInstance } from 'antd';
import { Modal, Select, Radio, Button, Drawer, Form, Input, InputNumber, Row, Space, Upload } from 'antd';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import type { DictData } from 'egenie-common';
import { objToDict, request } from 'egenie-common';
import React from 'react';
import type { MainItem } from './types';
import { getDefaultFileList, uploadFileListToCdn, validateFileList } from '../../../utils';
import { ENUM_COMPENSATION_TYPE } from './constants';
import type { UploadFile } from 'antd/lib/upload/interface';

function UploadConfirm(): Promise<boolean> {
  return new Promise((resolve) => {
    Modal.confirm({
      centered: true,
      content: '确定删除吗?',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });
}

interface CreateCompensationModalProps {
  courierData: ValueAndLabelData;
  responsibleUserData: ValueAndLabelData;
  ownerData: ValueAndLabelData;
  mainItem?: MainItem;
  callback?: (...arg: any[]) => any;
  onCancel?: (...arg: any[]) => any;
}

export const CreateCompensationModal: React.FC<CreateCompensationModalProps> = function({
  onCancel,
  callback,
  mainItem,
  courierData,
  responsibleUserData,
  ownerData,
}) {
  const formRef = React.useRef<FormInstance>();
  const [
    loading,
    setLoading,
  ] = React.useState<boolean>(false);

  const initCompensationType = mainItem && mainItem.compensationType != null ? `${mainItem.compensationType}` : ENUM_COMPENSATION_TYPE.express.value;
  const [
    compensationType,
    setCompensationType,
  ] = React.useState<string>(initCompensationType);

  function handleCompensationTypeChange(e) {
    setCompensationType(e.target.value);
    formRef.current.setFieldsValue({ reasonType: undefined });
    setCourierPicListFileList([]);
  }

  const [
    reasonTypeData,
    setReasonTypeData,
  ] = React.useState<ValueAndLabelData>([]);
  React.useEffect(() => {
    request<BaseData<DictData>>({
      url: '/api/cloud/wms/rest/compensation/reason/type',
      params: { compensationType },
    })
      .then((info) => setReasonTypeData(objToDict(info.data)));
  }, [compensationType]);

  const [
    valuePicListFileList,
    setValuePicListFileList,
  ] = React.useState<UploadFile[]>(getDefaultFileList(mainItem?.valuePicList));

  const [
    courierPicListFileList,
    setCourierPicListFileList,
  ] = React.useState<UploadFile[]>(getDefaultFileList(mainItem?.courierPicList));

  const initialValues = {
    ...mainItem,
    compensationType: initCompensationType,
    reasonType: mainItem && mainItem.reasonType != null ? `${mainItem.reasonType}` : undefined,
  };

  async function handleFinish(params: MainItem) {
    setLoading(true);
    try {
      await request({
        url: '/api/cloud/wms/rest/compensation/update',
        method: 'post',
        data: {
          ...params,
          id: mainItem?.id,
          courierPicList: await uploadFileListToCdn({
            obsConfigId: 1,
            fileList: courierPicListFileList,
          }),
          valuePicList: await uploadFileListToCdn({
            obsConfigId: 1,
            fileList: valuePicListFileList,
          }),
        },
      });

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <Drawer
      footer={(
        <Row justify="end">
          <Space size="small">
            <Button onClick={onCancel}>
              取消
            </Button>
            <Button
              loading={loading}
              onClick={() => formRef.current.submit()}
              type="primary"
            >
              确定
            </Button>
          </Space>
        </Row>
      )}
      maskClosable={false}
      onClose={onCancel}
      title={`${mainItem?.id ? '编辑' : '创建'}理赔`}
      visible
      width={600}
    >
      <Form
        initialValues={initialValues}
        labelCol={{ span: 4 }}
        layout="horizontal"
        onFinish={handleFinish}
        ref={formRef}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item
          label="理赔类型"
          name="compensationType"
          rules={[
            {
              required: true,
              message: '请选择理赔类型',
            },
          ]}
        >
          <Radio.Group
            onChange={handleCompensationTypeChange}
            optionType="button"
            options={Object.values(ENUM_COMPENSATION_TYPE)}
          />
        </Form.Item>
        <Form.Item
          label="货主"
          name="ownerId"
          rules={[
            {
              required: true,
              message: '请选择货主',
            },
          ]}
        >
          <Select
            allowClear
            notFoundContent="没有数据"
            options={ownerData}
            placeholder="请选择货主"
            style={{ width: '50%' }}
          />
        </Form.Item>
        <Form.Item
          label="快递公司"
          name="courierId"
          rules={[
            {
              required: true,
              message: '请选择快递公司',
            },
          ]}
        >
          <Select
            allowClear
            notFoundContent="没有数据"
            options={courierData}
            placeholder="请选择快递公司"
            style={{ width: '50%' }}
          />
        </Form.Item>
        <Form.Item
          label="平台单号"
          name="platformOrderCode"
          rules={[
            {
              required: true,
              message: '请输入平台单号',
            },
          ]}
        >
          <Input
            allowClear
            placeholder="请输入平台单号"
            style={{ width: '50%' }}
          />
        </Form.Item>
        <Form.Item
          label="快递单号"
          name="waybillCode"
          rules={[
            {
              required: true,
              message: '请输入快递单号',
            },
          ]}
        >
          <Input
            allowClear
            placeholder="请输入快递单号"
            style={{ width: '50%' }}
          />
        </Form.Item>
        <Form.Item
          label="问题类型"
          name="reasonType"
          rules={[
            {
              required: true,
              message: '请选问题类型',
            },
          ]}
        >
          <Select
            allowClear
            notFoundContent="没有数据"
            options={reasonTypeData}
            placeholder="请选择问题类型"
            style={{ width: '50%' }}
          />
        </Form.Item>
        <Form.Item
          label="理赔金额(元)"
          name="compensationPrice"
          rules={[
            {
              required: true,
              message: '请输入理赔金额',
            },
            {
              type: 'number',
              min: 0.01,
              message: '最小为0.01元',
            },
          ]}
        >
          <InputNumber
            max={9999999}
            min={0}
            placeholder="请输入理赔金额"
            step={0.01}
            style={{ width: '50%' }}
          />
        </Form.Item>
        <Form.Item
          label="问题描述"
          name="reason"
        >
          <Input.TextArea
            allowClear
            autoSize={{
              minRows: 2,
              maxRows: 4,
            }}
            maxLength={250}
            placeholder="请输入问题描述"
            showCount
          />
        </Form.Item>
        <Form.Item
          label="处理结果"
          name="compensationResult"
        >
          <Input.TextArea
            allowClear
            autoSize={{
              minRows: 2,
              maxRows: 4,
            }}
            maxLength={250}
            placeholder="请输入处理结果"
            showCount
          />
        </Form.Item>
        <Form.Item
          label="价值证明截图"
          name="valuePicList"
          rules={[
            {
              validator: () => validateFileList({
                fileList: valuePicListFileList,
                allowEmpty: true,
              }),
            },
          ]}
        >
          <Upload
            accept=".png,.jpg,.jpeg"
            beforeUpload={() => false}
            fileList={valuePicListFileList}
            listType="picture-card"
            maxCount={3}
            onChange={(info) => setValuePicListFileList([...info?.fileList].filter(Boolean))}
            onRemove={UploadConfirm}
          >
            <div className="egenie-common-upload-container">
              <i className="icon-add"/>
              <footer>
                上传图片
              </footer>
            </div>
          </Upload>
        </Form.Item>
        {
          compensationType === ENUM_COMPENSATION_TYPE.express.value ? (
            <Form.Item
              label="快递认责截图"
              name="courierPicList"
              rules={[
                {
                  validator: () => validateFileList({
                    fileList: courierPicListFileList,
                    allowEmpty: true,
                  }),
                },
              ]}
            >
              <Upload
                accept=".png,.jpg,.jpeg"
                beforeUpload={() => false}
                fileList={courierPicListFileList}
                listType="picture-card"
                maxCount={3}
                onChange={(info) => setCourierPicListFileList([...info?.fileList].filter(Boolean))}
                onRemove={UploadConfirm}
              >
                <div className="egenie-common-upload-container">
                  <i className="icon-add"/>
                  <footer>
                    上传图片
                  </footer>
                </div>
              </Upload>
            </Form.Item>
          ) : null
        }
        {
          compensationType === ENUM_COMPENSATION_TYPE.cloudWarehouse.value ? (
            <Form.Item
              label="责任人"
              name="responsibleUserId"
            >
              <Select
                allowClear
                notFoundContent="没有数据"
                options={responsibleUserData}
                placeholder="请选择责任人"
                style={{ width: '50%' }}
              />
            </Form.Item>
          ) : null
        }
      </Form>
    </Drawer>
  );
};

